* {
  margin: 0px;
  padding: 0px;
}
.auth {
  height: 100vh;
  overflow: hidden;
  background-color: #303841;
}
.textsss h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 55px !important;
}
.main {
  height: 373px;
  width: 1000px;
  margin-top: 6rem;
  display: flex;
  gap: 4rem;
}
.text1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bolder;
  font-size: 24px;
}
.textsss p {
  font-size: 18px !important;
  font-style: italic !important;
}
.btnss {
  background-color: #1877f2;
  color: white;
  border-radius: 5px;
  height: 40px;
  padding: 0px 10px;
  margin-top: 1rem;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left h2 {
  color: #1877f2;
  font-size: 45px;
  font-weight: bolder;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.left p {
  width: 75%;
  font-size: 24px;
  color: black;
}

.box {
  width: 396px;
  height: 350px;
  background-color: #262e35;
  border-radius: 10px;
  padding: 2rem;
  color: white;
  border: 2px solid #1877f2;
}

@media screen and (min-width: 1092px) {
  .box {
    margin-left: 7rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 1092px) {
  .box {
    margin-top: 2rem;
  }
  .main {
    gap: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0rem !important ;
  }
  .box {
    margin-right: 10rem !important;
  }
  .textsss h2 {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0rem !important ;
  }
  .box {
    margin-right: 30rem !important;
  }
  .left {
    margin-right: 20rem !important;
  }
  .textsss h2 {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .auth {
    display: flex;
    height: 100vh;
    align-items: center;
  }
  .main {
    margin-bottom: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0rem !important ;
  }
  .box {
    margin-right: 39rem !important;
    width: 340px;
    height: 340px;
  }
  .left {
    margin-right: 26rem !important;
  }
  .textsss {
    margin-left: 1rem;
  }
  .textsss h2 {
    font-size: 30px !important;
  }
  .textsss p {
    width: 60%;
  }
}
